.Burger {
    top: 5%;
    left: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 2rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;
}

.Burger:focus {
    outline: none;
}

.Burger-line {
    width: 2rem;
    height: 0.25rem;
    background-color: var(--brand-color-1);
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
}

.Burger-line_close {

}

.Burger-line:first-child {
    transform: rotate(0);
}

.Burger-line:nth-child(2) {
    opacity: 1;
    transform: translateX(0);
}

.Burger-line:nth-child(3) {
    transform: rotate(0);
}

.Burger-line_open {
    background-color: var(--brand-color-1);
}

.Burger-line_open:first-child {
    transform: rotate(45deg);
}

.Burger-line_open:nth-child(2) {
    opacity: 0;
    transform: translateX(20px);
}

.Burger-line_open:nth-child(3) {
    transform: rotate(-45deg);
}