.SectionInfo {
    max-width: 400px;
    padding: 35px;
}

.SectionInfo-body {
    padding-top: 20px;
}

.SectionInfo-desc {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-transform: inherit;
    color: var(--text-color);
    line-height: 25px;
}

@media (max-width: 860px) {
    .SectionInfo {
        max-width: 100%;
    }
}