.PriceCard {
    position: relative;
    max-width: 300px;
    min-width: 250px;
    width: 100%;
    height: 300px;
    background-color: var(--brand-color-2-sub);
    border-radius: 20px;
    box-shadow: 10px 11px 9px -11px rgba(0,0,0,0.62);
    -webkit-box-shadow: 10px 11px 9px -11px rgba(0,0,0,0.62);
    -moz-box-shadow: 10px 11px 9px -11px rgba(0,0,0,0.62);
}

.PriceCard__header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 25%;
    padding: 4px 0;
    border-radius: 20px 20px 0 0;
    background-color: var(--brand-color-1);
}

.PriceCard__header-title {
    text-align: center;
    font-weight: 700;
    font-size: 22px;
    text-transform: uppercase;
    margin-block-start: 0;
    margin-block-end: 0;
    color: var(--text-color);
}

.PriceCard__header-description {
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    margin-block-start: 0;
    margin-block-end: 0;
    line-height: 25px;
    color: var(--text-color);
}

.PriceCard__body {
    text-align: center;
    padding: 16px;
}

.PriceCard__price {
    display: block;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    color: var(--text-color);
}

.PriceCard__price_bold {
    font-weight: 600;
}

.PriceCard__price-description {
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    margin-block-start: 0;
    margin-block-end: 0;
    line-height: 25px;
    padding-bottom: 8px;
    color: var(--brand-white);
}

.PriceCard__price_main {
    color: var(--brand-color-1);
}

.PriceCard__price_through {
    text-decoration: line-through;
}

.PriceCard__footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 20px 0;
    text-align: center;
}