.Card {
    width: 220px;
    padding: 24px 16px;
    border: 1px solid rgba(232, 234, 236, 1);
    border-radius: 12px;
    border-width: 1px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background-color: rgba(255, 255, 255, 1);
}

.CardLine {}

.CardLine::before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: rgba(232, 234, 236, 1);
    margin: 16px 0;
}
