.Coworking {}

.section-media {
    
}

.location-map {
    width: 100%;
    background-image: url(../../assets/map4-min.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-size: cover;
}

.Coworking-section {
    display: flex;
    justify-content: space-between;
    height: 100%;
}

.Coworking__title {
    font-weight: 700;
    font-size: 42px;
    color: var(--text-color);
    margin: 0;
    text-align: left;
    text-transform: uppercase;
}

.Coworking__title_padding {
    padding-top: 30px;
}

.Coworking__title_center {
    text-align: center;
}

.Coworking__text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-transform: inherit;
    color: var(--text-color);
    line-height: 25px;
}

.amenities {
    display: flex;
}

.amenities-slider {
    width: 100%;
}

.amenities-info {
    padding: 50px;
    background-color: var(--brand-color-2);
}

.amenities-team {
    padding: 50px 50px 0 50px;
    background-color: var(--brand-color-2-sub);
    height: 100%;
}

.Coworking-section__prices {
    padding: 50px;
    background-image: url(../../assets/pattern1.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0px 0px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.Coworking__table {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 50px;
}

.Coworking__price {
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    margin-block-start: 0;
    margin-block-end: 0;
    line-height: 25px;
}

.Coworking__price-item {
    color: var(--text-color);
}

.awssld {
    height: 100%;
}

.awssld__container {
    height: 100px;
}

@media (max-width: 860px) {
    .Coworking-section__prices {
        padding: 20px;
    }
    .amenities {
        flex-wrap: wrap;
    }

    .location-map {
        display: none;
    }

    .location-info {
        max-width: 100%;
    }

    .Coworking-section__location {
        min-height: 0;
    }

    .amenities {
        max-width: 100%;
    }

    .amenities-team {
        padding: 50px;
    }
    .Coworking-section__amenities {
        flex-wrap: wrap;
    }
  }