.Coffeeshop {
    display: flex;
    /* justify-content: space-between; */
}

.Coffeeshop-media {
    width: 100%;
    background-image: url(../../assets/coffeeshop-image-min.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.Coffeeshop-info {
    max-width: 30%;
    width: 100%;
    padding: 50px;
    background-color: var(--brand-color-1);
}

.Coffeeshop-title {
    font-weight: 700;
    font-size: 30px;
    color: var(--text-color);
    margin: 0;
    text-align: left;
    text-transform: uppercase;
}

.Coffeeshop-sub-title {
    font-weight: 700;
    font-size: 26px;
    color: var(--text-color);
    margin: 0;
    text-align: left;
    text-transform: uppercase;
}

.Coffeeshop-sub-title_padding {
    padding-bottom: 20px;
}

.Coffeeshop-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-transform: inherit;
    color: var(--text-color);
    line-height: 25px;
}

.Coffeeshop-text > * {
    list-style-type: disc; 
}

@media (max-width: 860px) {
    .Coffeeshop {
        flex-wrap: wrap;
    }
    .Coffeeshop-media {
        display: none;
    }

    .Coffeeshop-info {
        max-width: 100%;
    }
}

.Coffeeshop__list > * {
    padding-bottom: 8px;
}

.Coffeeshop__list-item {
    position: relative;
    display: flex;
    padding-left: 32px;
}

.Coffeeshop__icon_beans::before {
    content: '\2615';
    position: absolute;
    left: 0;
}

.Coffeeshop__icon_coffee::before {
    content: '\1F31F';
    position: absolute;
    left: 0;
}

.Coffeeshop__icon_barista::before {
    content: '\1F64B';
    position: absolute;
    left: 0;
}

.Coffeeshop__icon_water::before {
    content: '\1F4A7';
    position: absolute;
    left: 0;
}

.Coffeeshop__icon_pets::before {
    content: '\1F436';
    position: absolute;
    left: 0;
}