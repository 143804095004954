.Dialog {
    position: fixed; /* Stay in place */
    z-index: 100; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    border-radius: 5px;
}

.Dialog-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--brand-white);
  margin: auto;
  padding: 40px;
  border: 1px solid #888;
  width: 100%;
  max-width: 300px;
  min-height: 400px;
  max-height: 100%;
  border-radius: 5px;
}

.Dialog-close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    position: absolute;
    right: 15px;
    top: 3px;
  }
  
  .Dialog-close:hover,
  .Dialog-close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  .DialogOrderReminder {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  .DialogOrderReminder-title {
    font-size: 28px;
  }

  .DialogOrderReminder-title {
    font-weight: 700;
    font-size: 24px;
    color: var(--brand-color-3);
    padding-bottom: 12px;
  }

  .DialogOrderReminder-text {
    font-weight: 500;
    font-size: 16px;
    color: var(--brand-color-3);
  }

  .DialogOrderReminder-content > *:not(:last-child) {
    padding-bottom: 12px;
  }

.DialogOrderReminder-img {
  width: 100%;
}