.InfoBlock {}

.InfoBlock__wrapper {
  display: flex;
  height: 100%;
  position: relative;
}

.section-media {
  width: 100%;
  background-image: url(../../assets/pattern1.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0px 0px;
  filter: blur(4px);
  -webkit-filter: blur(4px);
}

.InfoBlock__words {
  font-weight: 500;
  font-size: 44px;
  color: var(--text-color);
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0p;
  margin-inline-end: 0;
  right: 0;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 68%;
  transform: translate(-50%, -50%);
  width: 50%;
  text-align: center;
  text-transform: uppercase;
}

.InfoBlock__word {
  background-color: var(--brand-color-1);
}

@media (max-width: 860px) {
  .section-media {
      display: none;
  }

  .InfoBlock__words {
    display: none;
  }

  .section-info {
    max-width: 100%;
  }

  .InfoBlock__wrapper {
    min-height: 0;
  }
}