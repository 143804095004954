.About {
    display: flex;
    height: 100%;
}

.About-img {
    margin: 0;
    width: 100%;
    height: 100%;
}

.About-info {
    max-width: 30%;
    width: 100%;
    padding: 50px;
    background-color: var(--brand-color-1);
}

.About-title {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: var(--text-color);
    margin: 0;
    text-align: left;
    text-transform: uppercase;
}

.About-sub-title_padding {
    padding-bottom: 20px;
}

.About-text {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-transform: inherit;
    color: var(--text-color);
    line-height: 25px;
}

.About-media {
    background-image: url(../../assets/pattern1.png);
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

@media (max-width: 860px) {
    .About-media {
        display: none;
    }

    .About-info {
        max-width: 100%;
    }
}