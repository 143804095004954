.Button {
    padding: 0;
    border: none;
    background: none;
    padding: 15px 40px;
    background-color: var(--brand-color-1);
    border-radius: 5px;
    cursor: pointer;
    color: var(--brand-white);
    font-size: 16px;
}

.Button:focus {
    box-shadow: 2px 2px 5px var(--brand-color-2);
}

.Button:hover {
    box-shadow: 2px 2px 5px var(--brand-color-2);
}

.Button:focus, .Button:focus{
    outline: none;
}