.Footer {
    display: flex;
    justify-content: space-between;
    align-items: start;
    padding: 35px;
}

.Footer__navigation {
    display: flex;
    justify-content: space-between;
}

.Footer__info {
    flex-grow: 1;
}

.navigation__section-title {
    font-weight: 700;
    font-size: 26px;
    color: var(--brand-color-2);
    margin: 0;
    text-align: left;
    text-transform: uppercase;
}

.info-title {
    font-weight: 700;
    font-size: 26px;
    color: var(--text-color);
    margin: 0;
    text-align: left;
    text-transform: uppercase;
}

.info-desc {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-transform: inherit;
    color: var(--text-color);
    line-height: 25px;
    text-transform: uppercase;
}

.email-link {
    color: var(--brand-color-1)!important;
}

.navigation__links > * {
    padding-bottom: 4px;
}

@media (max-width: 860px) {
    .Footer {
        flex-direction: column;
    }

    .Footer__navigation {
        flex-direction: column;
    }
}