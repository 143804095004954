.Title {
    font-weight: 700;
    font-size: 2.5rem;
    color: var(--text-color);
    text-transform: uppercase;
}

@media (max-width: 860px) {
    .Title {
        font-size: 1.5rem;
    }
}