.PriceTable-wrapper {
    width: 100%;
    display: flex;
    justify-content: start;
    gap: 24px;
    flex-wrap: wrap;
}

.PriceTable {
    width: 100%;
}

.PriceTable__head {
    display: flex;
    align-items: center;
    text-transform: uppercase;
}

.PriceTable__body {
    display: flex;
    background-color: var(--brand-color-1);
    border-radius: 10px;
    padding: 16px;
}

.PriceTable__col {
    padding: 16px;
    /* width: 30%; */
    width: 100%;
    max-width: 100%;
}

.PriceTable__col-title {
    color: var(--brand-color-2);
}

.col-content_center {
    text-align: center;
}

.PriceTable__col_bg {
    background-color: var(--brand-white);
}

.PriceTable__col_first {
    border-radius: 10px 0 0 10px;
}

.PriceTable__col_last {
    border-radius: 0 10px 10px 0;
}

.PriceTable__row {
    min-height: 80px;
    padding: 4px;
}

.PriceTable__row_num {
    white-space: nowrap;
}

.head__title {
    font-size: 15px;
    color: var(--brand-color-1);
    text-align: start;
}

.PriceTable__text {
    font-size: 16px;
    font-weight: bold;
}

.PriceTable__text-color_blue {
    color: var(--brand-color-1);
}

.PriceTable__text-color_white {
    color: var(--brand-white);
}

.PriceTable__title {
    font-weight: 700;
    font-size: 20px;
    margin: 0;
    text-transform: uppercase;
    color: var(--brand-color-1);
}

@media (max-width: 640px) {
    .PriceTable-wrapper {
        justify-content: center;
    }

    .PriceTable__row {
        font-size: 8px;
        /* width: 40px; */
    }

    .head__title {
        font-size: 20px;
        text-align: center;
    }

    .PriceTable__body {
        padding: 0;
    }

    .PriceTable__col {
        padding: 10px;
    }

    .PriceTable__title {
        font-size: 20px;
    }
}

.PriceTable__row_tech {
    position: relative;
}

.PriceTable__row_fixed-height {
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: pre-line;
    position: relative;
}

.PriceTable__footer {
    padding-top: 20px;
}

.PriceTable__footer-desc {
    font-size: 16px;
    color: var(--brand-color-1);
    margin-bottom: 10px;
    font-weight: bold;
}

.PriceTable_padding {
    padding-top: 30px;
}

.PriceTable__title_center {
    text-align: center;
}
