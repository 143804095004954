.Navbar {
    display: flex;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0;
}

.Navbar_horisontal {
    align-items: center;
}

.Navbar_vertical {
    flex-direction: column;
    align-items: start;
}

.Navbar_vertical > *:not(:last-child) {
    margin-bottom: 20px;
}

.Navbar_horisontal > *:not(:last-child) {
    margin-right: 40px;
}

.navigation__navbar {
    margin-top: 25px;
}