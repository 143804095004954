.RateCard {
    display: inline;
}

.RateCardTop {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.RateCardTop > *:not(:first-child):not(:last-child) {
    margin-bottom: 18px;
}

.RateCardTop > *:first-child {
    margin-bottom: 4px;
}

.RateCardTop > *:last-child {
    margin-bottom: 2px;
}

.RateCardTopIcon {
    width: 90px;
    height: 90px;
}

.RateCardTopDesc {
    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0%;
    text-align: center;
    color: rgba(31, 49, 74, 1);  
}

.RateCardTopTitle {
    font-weight: 700;
    font-size: 21px;
    line-height: 100%;
    letter-spacing: 0%;
    text-align: center;
    color: rgba(29, 80, 134, 1);    
}

.RateCardBottom {
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    letter-spacing: 0%;
    color: var(--color-text-secondary, rgba(103, 116, 130, 1));
}

.RateCardBottomList > * {
    margin-bottom: 12px;
}

.RateCardBottomItem {
    display: flex;
    align-items: center;
    gap: 8px;
}

.RateCardBottomIcon {
    margin: 0;
}

.RateCardBottomText {
    /* padding-left: 40px; */
}

.RateCardBottomItemNotActive {
    opacity: 0.4;
}
