.ErrorPage {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: calc(100% - 100px);
}

.ErrorPage-content {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    text-align: center;
}

.ErrorPage-text {
    color: var(--brand-color-3);
    text-shadow: 3px 3px #558abb;
    text-transform: uppercase;
}

.ErrorPage_bg {
    width: 100%;
    background-image: url(../../assets/pattern1.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.ErrorPage-title {
    font-size: 80px;
}

.ErrorPage-desc {
    font-size: 28px;
}

.ErrorPage-link {
    color: var(--brand-color-3);
}

.ErrorPage-content *:first-child {
    margin-bottom: 8px;
}

.ErrorPage-content *:not(:first-child) {
    margin-bottom: 12px;
}