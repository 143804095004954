.EventsPage {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px 58px;
}

.EventTypes {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    gap: 58px;

    width: 100%;
}

.EventType {
    position: relative;
    flex-grow: 1;

    width: 45%;
    height: 100%;
}

.EventType > .EventsGroup:not(:first-child) {
    margin-top: 24px;
}


.EventType > *:nth-child(2) {
    margin-top: 32px;
}

.EventType::after {
    position: absolute;
    content: '';
    width: 10px;
    height: 100%;
    left: -34px;
    background-color: var(--text-color);
    top: 0;
    z-index: 1;
}

.EventTypeTitle {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 40px;
    line-height: 1;
    color: var(--text-color)
}

.EventsGroup {
    display: flex;
    gap: 24px;
}

.EventsGroup:first-child {
    margin-top: 32px;
}

.EventsWrapper {
    position: relative;
    width: 100%;
}

.EventsWrapper::before {
    position: absolute;

    content: '';
    width: 100%;
    height: 10px;
    background-color: var(--brand-color-2-sub);
}

.Events {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: transparent;

    margin-top: 20px;
}

.Events .Event:not(:first) {
    margin-top: 12px;
}

.EventLink {
    display: flex;
    align-items: center;
    gap: 8px;
    color: (--bs-link-color);
}

.EventDesc {
    cursor: pointer;
}

.EventsBox {
    position: relative;
    display: flex;
    justify-content: center;

    min-width: 160px;
    min-height: 160px;
    background-color: var(--brand-color-2-sub);

    padding: 24px;
}

.EventsDate {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    /* position: absolute; */
    font-weight: bold;
    text-transform: uppercase;
    font-size: 32px;
    line-height: 1;
    color: var(--text-color)
}

.EventsDate > * {
    margin-bottom: 12px;
}

.EventsDate > *:first-child {
    font-size: 24px;
}

.EventsDateDayBox {
    position: absolute;

    display: flex;
    justify-content: center;
    align-items: center;

    top: 40px;
    min-width: 120px;
    min-height: 120px;
    background-color: aquamarine;
    right: -50px;
}

.Events > .Event:not(:last-child) {
    margin-bottom: 8px;
}

@media (min-width:320px)  {
    .EventsPage {
        padding: 24px 8px;
    }

    .Events {
        margin-top: 20px;
    }

    .EventsWrapper::before {
        display: none;
    }

    .EventsGroup {
        flex-direction: column;
        gap: 8px;
    }

    .EventType {
        width: 100%;
    }
    
    .EventTypes {
        gap: 24px;
    }

    .EventsDate {
        font-size: 20px;
    }

    .EventsBox {
        min-width: 40px;
        min-height: 10px;
    }

    .EventTypeTitle {
        font-size: 24px;
    }
}

@media (min-width:480px)  { 
    .EventsDate > *:first-child {
        font-size: 12px;
    }

    .EventsPage {
        padding: 24px 8px;
    }

    .Events {
        margin-top: 20px;
    }

    .EventsGroup {
        flex-direction: row;
        gap: 8px;
    }

    .EventsWrapper::before {
        display: block;
    }

    .EventType {
        width: 100%;
    }
    
    .EventTypes {
        gap: 24px;
    }

    .EventsDate {
        font-size: 24px;
    }

    .EventsBox {
        min-width: 40px;
        min-height: 40px;
    }

    .EventTypeTitle {
        font-size: 24px;
    }
}

@media (min-width:600px)  {
    .EventsDate > *:first-child {
        font-size: 16px;
    }

    .EventsPage {
        padding: 24px 58px;
    }

    .Events {
        margin-top: 20px;
    }

    .EventsGroup {
        flex-direction: row;
        gap: 24px;
    }

    .EventsWrapper::before {
        display: block;
    }

    .EventTypes {
        gap: 24px;
    }
    
    .EventType {
        width: 100%;
    }

    .EventsDate {
        font-size: 24px;
    }

    .EventsBox {
        min-width: 80px;
        min-height: 80px;
    }

    .EventTypeTitle {
        font-size: 24px;
    }
}
@media (min-width:801px)  { 
    .EventsDate > *:first-child {
        font-size: 20px;
    }

    .EventsPage {
        padding: 24px 58px;
    }

    .Events {
        margin-top: 20px;
    }

    .EventsGroup {
        flex-direction: row;
        gap: 24px;
    }

    .EventsWrapper::before {
        display: block;
    }

    .EventTypes {
        gap: 58px;
    }

    .EventType {
        width: 45%;
    }

    .EventsBox {
        min-width: 80px;
        min-height: 80px;
    }

    .EventsDate {
        font-size: 32px;
    }

    .EventTypeTitle {
        font-size: 40px;
    }
}

@media (min-width:1025px) { 
    .EventsDate > *:first-child {
        font-size: 24px;
    }

    .EventsPage {
        padding: 24px 58px;
    }

    .Events {
        margin-top: 20px;
    }

    .EventsGroup {
        flex-direction: row;
        gap: 24px;
    }

    .EventsWrapper::before {
        display: block;
    }

    .EventTypes {
        gap: 58px;
    }

    .EventType {
        width: 45%;
    }

    .EventsBox {
        min-width: 160px;
        min-height: 160px;
    }

    .EventsDate {
        font-size: 32px;
    }

    .EventTypeTitle {
        font-size: 40px;
    }
}

@media (min-width:1281px) { 
    .EventsDate > *:first-child {
        font-size: 24px;
    }

    .EventsPage {
        padding: 24px 58px;
    }

    .Events {
        margin-top: 20px;
    }

    .EventsGroup {
        flex-direction: row;
        gap: 24px;
    }

    .EventsWrapper::before {
        display: block;
    }
    
    .EventTypes {
        gap: 58px;
    }

    .EventType {
        width: 45%;
    }

    .EventsBox {
        min-width: 160px;
        min-height: 160px;
    }

    .EventsDate {
        font-size: 32px;
    }

    .EventTypeTitle {
        font-size: 40px;
    }
 }