.Nav {
    position: relative;
}

.Nav__text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-transform: uppercase;
    color: var(--text-color);
    flex: none;
    order: 0;
    flex-grow: 0;
    transition: .2s;
    padding: 5px 0;
    line-height: 25px;
    cursor: pointer;
}

.Nav__text:hover {
    transition: .2s;
    background-color: var(--brand-color-1);
    padding: 5px 0 ;
}