.switch {
    display: inline-block;
}

.switch > span {
    position: relative;        
    width: 70px;
    height: 34px;
    float: left;
}

.switch > span > input {
    display: none;
}

.switch > span > input:checked + .slider {
    background-color: var(--brand-color-1);
}
.switch > span > input:checked + .slider:before {
    transform: translateX(26px);
}
.switch > span > input:focus + .slider {
    box-shadow: 0 0 1px #218d96;
}

.switch > label {
    line-height: 34px;
    margin-left: 12px;
    cursor: pointer;
}

.slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    border: 0;
    outline: none;
    background-color: var(--brand-color-2);
    transition: .4s;
    border-radius: 34px;
    cursor: pointer;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

.slider_true:before {
    left: 14px;
}

.slider_false:before {
    left: 4px;
}

.button__text {
    font-weight: 600;
    font-size: 16px;
    color: var(--brand-white);
    text-transform: uppercase;
    line-height: 25px;
}

.button__text_left {
    text-align: left;
    padding-left: 5px;
}

.button__text_right {
    text-align: right;
    padding-right: 5px;
}