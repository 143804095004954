.BookingPage {
    position: relative;
    height: calc(100% - 170px);
}

.BookingPageHeader {
    background-image: url(../../assets/booking.png);
    width: 100%;
    height: 300px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    filter: blur(4px);
    -webkit-filter: blur(4px);
}

.BookingPageLabel {
    font-size: 20px;
    padding: 8px;
}

.BookingPageText {
    font-size: 16px;
}

.BookingPageBlock {
    width: 35%;
}

.BookingPageBlock > *:not(:last-child) {
    margin-bottom: 20px;
}

.BookingPageFormBlockWrapper {
    display: flex;
    flex-direction: row;
}

.BookingPageForm {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}

.BookingPageForm > * {
    margin-bottom: 12px!important;
}

.BookingPageFormButton {
    margin-top: 24px!important;
    background-color: var(--brand-color-1)!important;
}

.DialogClose {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    position: absolute;
    right: 15px;
    top: 3px;
    cursor: pointer;
    border: none;   
    background-color: transparent;
    padding: 0;
}

.BookingPageFieldGroup {
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.NumberOfPersonsHint {
    color: var(--first-color);
}

.BookingPageTextMin {
    font-size: 12px;
    color: var(--brand-gray);
}

.BookingPageContent {
    width: 100%;
    max-width: 70%;
    margin: 0 auto;
    display: flex;
    gap: 40px;
    justify-content: center;
    height: 100%;
    padding: 40px;
}

.BookingPageTitle {
    margin: 0;
    font-weight: 700;
    font-size: 42px;
    color: var(--text-color);
    width: 100%;
    margin-block-start: -10px;
    margin-block-end: 0;
    text-align: start;
}

.BookingPageDesc {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    text-transform: inherit;
    line-height: 40px;
    color: var(--text-color);
}

.BookingPageBlockText {
    display: flex;
    justify-content: end;
    color: white;
}

.BookingPageFormTitle {
    margin: 0;
    font-weight: 700;
    font-size: 24px;
    color: var(--text-color);
    text-transform: uppercase;
    width: 400px;
    text-align: start;
}

@media (min-width:320px) {
    .BookingPageContent {
        flex-direction: column;
        height: calc(100% + 170px);
    }
    
    .BookingPageBlock {
        width: 100%;
    }

    .BookingPageTitle {
        text-align: center;
    }

    .BookingPageFormBlockWrapper {
        justify-content: center;
    }

    .BookingPageForm {
        width: 100%;
        max-width: 300px;
    }

    .BookingPageLabel {
        font-size: 16px;
    }

    .BookingPageFormTitle {
        text-align: start;
    }

    .BookingPageFieldGroup {
        flex-direction: column;
    }
}

@media (min-width:480px)  { 
    .BookingPageTitle {
        text-align: center;
    }

    .BookingPageLabel {
        font-size: 16px;
    }

    .BookingPageContent {
        flex-direction: column;
    }

    .BookingPageForm {
        width: 100%;
        max-width: 100%;
    }

    .BookingPageFormTitle {
        text-align: center;
    }

    .BookingPageFieldGroup {
        flex-direction: column;
    }
}

.BookingPageFormWrapper {
    width: 100%;
}

.BookingPageFormWrapper > *:not(:last-child) {
    margin-bottom: 8px;
}

.BookingPageExternalLink {
    font-size: 20px;
}

@media (min-width:600px)  {
    .BookingPageTitle {
        text-align: center;
    }

    .BookingPageLabel {
        font-size: 20px;
    }

    .BookingPageContent {
        flex-direction: column;
    }

    .BookingPageForm {
        width: 100%;
        max-width: 100%;
    }

    .BookingPageFormTitle {
        text-align: start;
    }

    .BookingPageFieldGroup {
        flex-direction: row;
    }
}

@media (min-width:801px)  { 
    .BookingPageTitle {
        text-align: start;
    }

    .BookingPageLabel {
        font-size: 24px;
    }

    .BookingPageContent {
        max-width: 80%;
        flex-direction: row;
    }

    .BookingPageForm {
        width: 100%;
        max-width: 100%;
    }

    .BookingPage {
        height: calc(100% - 170px);
    }   

    .BookingPageFormTitle {
        text-align: start;
    }

    .BookingPageFieldGroup {
        flex-direction: row;
    }
}

@media (min-width:1025px) {
    .BookingPageTitle {
        text-align: start;
    }

    .BookingPageLabel {
        font-size: 24px;
    }

    .BookingPageContent {
        max-width: 70%;
        flex-direction: row;
    }

    .BookingPageForm {
        width: 100%;
        max-width: 100%;
    }

    .BookingPage {
        height: calc(100% - 170px);
    }   

    .BookingPageFormTitle {
        text-align: start;
    }

    .BookingPageFieldGroup {
        flex-direction: row;
    }
}

@media (min-width:1281px) { 
    .BookingPageTitle {
        text-align: start;
    }

    .BookingPageLabel {
        font-size: 24px;
    }

    .BookingPageContent {
        max-width: 70%;
        flex-direction: row;
    }

    .BookingPageForm {
        width: 100%;
        max-width: 100%;
    }

    .BookingPage {
        height: calc(100% - 170px);
    }
    
    .BookingPageFormTitle {
        text-align: start;
    }

    .BookingPageFieldGroup {
        flex-direction: row;
    }
}
