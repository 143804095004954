.Sidebar {
    top: 100px;
    background-color: var(--brand-white);
    position: absolute;
    height: 100vh;
    width: 30%;
    left: 0;
    padding: 30px;
    transition: transform 0.3s ease-in-out;
}

.Sidebar_open {
    transform: translateX(0);
}

.Sidebar_close {
    transform: translateX(-100%);
}