.FreeDayPage {
    height: 100%;
}

.FreeDayPageHeader {
    height: 200px;

    display: flex;
    justify-content: center;

    background-color: var(--brand-color-2);

    --mask:
    radial-gradient(111.80px at 50% calc(100% - 150.00px),#000 99%,#0000 101%) calc(50% - 100px) 0/200px 100%,
    radial-gradient(111.80px at 50% calc(100% + 100.00px),#0000 99%,#000 101%) 50% calc(100% - 50px)/200px 100% repeat-x;
  -webkit-mask: var(--mask);
          mask: var(--mask);
}

.FreeDayPageFooter {
    display: flex;
    align-items: flex-end;
    justify-self: center;

    height: 100px;
    width: 100%;
    padding: 12px;

    display: flex;
    justify-content: center;

    background-color: var(--brand-color-2);

    --mask:
    radial-gradient(111.80px at 50% 150.00px,#000 99%,#0000 101%) calc(50% - 100px) 0/200px 100%,
    radial-gradient(111.80px at 50% -100px,#0000 99%,#000 101%) 50% 50px/200px 100% repeat-x;
  -webkit-mask: var(--mask);
          mask: var(--mask);
}

.FreeDayPageFooterTitle {
    font-size: 20px;
    font-weight: bold;
}

.FreeDayPageHeaderTitle {
    font-size: 100px;
    color: var(--brand-white);
    text-align: center;

    text-shadow: 5px 5px var(--text-color);
    text-transform: uppercase;
}

.FreeDayPageBody {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 400px;
    padding: 0 48px;
}

.FreeDayPageBodyTitle {
    font-size: 80px;
    color: var(--brand-color-2);

    text-shadow: 5px 5px var(--text-color);
    text-transform: uppercase;
}

.FreeDayPageHeaderContent {
    max-width: 60%;
    width: 100%;

    margin: 24px 0;
}

.FreeDayPageBodyContent {
}

.FreeDayPageBodyImg {
    width: 100%;
    height: 100%;
    background-image: url(../../assets/cat.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.FreeDayPageCard {
    border-radius: 10px;

    padding: 24px;
    box-shadow: 5px 5px var(--text-color);

    font-size: 20px;
    font-weight: bold;

    cursor: pointer;
}

.FreeDayPageCardTextLeft {
    text-align: left;
}

.FreeDayPageCardTextCenter {
    text-align: center;
}

.FreeDayPageCardSmall {
    width: 25%;
}

.FreeDayPageCardBig {
    width: 40%;
}

.FreeDayPageCard1 {
    margin-left: 15%;
    background-color: var(--brand-color-3);
}

.FreeDayPageCard2 {
    margin-right: 10%;
    background-color: var(--brand-color-6);
}

.FreeDayPageCard3 {
    margin-right: 10%;
    background-color: var(--brand-color-5);
}

.FreeDayPageCard4 {
    background-color: var(--brand-color-3);
}

.FreeDayPageCardItem {
    margin-bottom: 8px;
}

.FreeDayPageList {
}

.FreeDayPageListTitle {
    margin-bottom: 12px;
}

.FreeDayPageListItem {
    position: relative;
    padding-left: 28px;
    margin-bottom: 4px;
}

.FreeDayPageListItem1::before {
    content: "🆓";
    background-size: contain;
    display: inline-block;
    width: 1em;
    height: 1em;
    position: absolute;
    top: 0.1rem;
    left: 0;
}

.FreeDayPageListItem2::before {
    content: "🚀";
    background-size: contain;
    display: inline-block;
    width: 1em;
    height: 1em;
    position: absolute;
    top: 0.1rem;
    left: 0;
}

.FreeDayPageListItem3::before {
    content: "⏰";
    background-size: contain;
    display: inline-block;
    width: 1em;
    height: 1em;
    position: absolute;
    top: 0.1rem;
    left: 0;
}

.FreeDayPageListItem4::before {
    content: "☕";
    background-size: contain;
    display: inline-block;
    width: 1em;
    height: 1em;
    position: absolute;
    top: 0.1rem;
    left: 0;
}

.FreeDayPageListItem5::before {
    content: "💰";
    background-size: contain;
    display: inline-block;
    width: 1em;
    height: 1em;
    position: absolute;
    top: 0.1rem;
    left: 0;
}

.FreeDayPageListItem6::before {
    content: "🌐";
    background-size: contain;
    display: inline-block;
    width: 1em;
    height: 1em;
    position: absolute;
    top: 0.1rem;
    left: 0;
}

.FreeDayPageParallaxLayerCard1 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.FreeDayPageParallaxLayerCard2 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.FreeDayPageParallaxLayerCard3 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.FreeDayPageParallaxLayerCard4 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.FreeDayPageNextBtn {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;

    font-size: 60px;
    z-index: 1000;
}

@media screen and (min-width:320px)  {
    .FreeDayPageFooterTitle {
        font-size: 14px;
    }

    .FreeDayPageHeader {
        height: 140px;
    }

    .FreeDayPageBody {
        height: 450px;
        padding: 0 24px;
    }

    .FreeDayPageHeaderTitle {
        font-size: 30px;
    }

    .FreeDayPageBodyTitle {
        font-size: 40px;
        text-align: center;
    }

    .FreeDayPageCard {
        width: 100%;
        margin: 28px;
        font-size: 14px;
    }

    .FreeDayPageParallaxLayerCard1 {
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }

    .FreeDayPageParallaxLayerCard2 {
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }    

    .FreeDayPageParallaxLayerCard3 {
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }    

    .FreeDayPageCardSmall {
        width: 100%;
    }
    
    .FreeDayPageCardBig {
        width: 100%;
    }

    .FreeDayPageCard1 {
        margin-top: 30%;
    }

    .FreeDayPageCard2 {
        margin-top: 80%;
    }

    .FreeDayPageCard3 {
        margin-top: 80%;
    }
}

@media screen and (min-width:480px)  {
    .FreeDayPageFooterTitle {
        font-size: 20px;
    }

    .FreeDayPageHeader {
        height: 200px;
    }

    .FreeDayPageBody {
        height: 450px;
        padding: 0 24px;
    }

    .FreeDayPageHeaderTitle {
        font-size: 40px;
    }

    .FreeDayPageBodyTitle {
        font-size: 40px;
        text-align: center;
    }

    .FreeDayPageCard {
        width: 100%;
        margin: 28px;
        font-size: 14px;
    }


    .FreeDayPageParallaxLayerCard1 {
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }

    .FreeDayPageParallaxLayerCard2 {
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }    

    .FreeDayPageParallaxLayerCard3 {
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }    

    .FreeDayPageCardSmall {
        width: 100%;
    }
    
    .FreeDayPageCardBig {
        width: 100%;
    }

    .FreeDayPageCard1 {
        margin-top: 50%;
    }

    .FreeDayPageCard2 {
        margin-top: 80%;
    }

    .FreeDayPageCard3 {
        margin-top: 80%;
    }
}

@media screen and (min-width:600px)  {
    .FreeDayPageFooterTitle {
        font-size: 20px;
    }

    .FreeDayPageHeader {
        height: 200px;
    }

    .FreeDayPageBody {
        height: 450px;
        padding: 0 24px;
    }

    .FreeDayPageHeaderTitle {
        font-size: 50px;
    }

    .FreeDayPageBodyTitle {
        font-size: 40px;
        text-align: center;
    }

    .FreeDayPageCard {
        width: 100%;
        margin: 28px;
        font-size: 14px;
    }


    .FreeDayPageParallaxLayerCard1 {
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }

    .FreeDayPageParallaxLayerCard2 {
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }    

    .FreeDayPageParallaxLayerCard3 {
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }    

    .FreeDayPageCardSmall {
        width: 100%;
    }
    
    .FreeDayPageCardBig {
        width: 100%;
    }

    .FreeDayPageCard1 {
        margin-top: 40%;
    }

    .FreeDayPageCard2 {
        margin-top: 60%;
    }

    .FreeDayPageCard3 {
        margin-top: 60%;
    }
}

@media screen and (min-width:801px)  {
    .FreeDayPageFooterTitle {
        font-size: 20px;
    }

    .FreeDayPageHeader {
        height: 200px;
    }

    .FreeDayPageBody {
        height: 450px;
        padding: 0 48px;
    }

    .FreeDayPageHeaderTitle {
        font-size: 60px;
    }

    .FreeDayPageBodyTitle {
        font-size: 60px;
        text-align: center;
    }

    .FreeDayPageCard {
        width: 100%;
        margin: 24px;
        font-size: 20px;
    }

    .FreeDayPageCardSmall {
        width: 25%;
    }
    
    .FreeDayPageCardBig {
        width: 40%;
    }

    .FreeDayPageParallaxLayerCard1 {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    
    .FreeDayPageParallaxLayerCard2 {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    
    .FreeDayPageParallaxLayerCard3 {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .FreeDayPageCard1 {
        margin-left: 10%;
    }

    .FreeDayPageCard2 {
        margin-right: 10%;
    }

    .FreeDayPageCard3 {
        margin-right: 10%;
    }
}

@media screen and (min-width:1025px) { 
    .FreeDayPageFooterTitle {
        font-size: 20px;
    }

    .FreeDayPageHeader {
        height: 200px;
    }

    .FreeDayPageBody {
        height: 400px;
        padding: 0 48px;
    }

    .FreeDayPageHeaderTitle {
        font-size: 80px;
    }

    .FreeDayPageBodyTitle {
        font-size: 60px;
    }

    .FreeDayPageCard {
        font-size: 20px;
    }

    .FreeDayPageCardSmall {
        width: 25%;
    }
    
    .FreeDayPageCardBig {
        width: 40%;
    }

    .FreeDayPageParallaxLayerCard1 {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    
    .FreeDayPageParallaxLayerCard2 {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    
    .FreeDayPageParallaxLayerCard3 {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .FreeDayPageCard1 {
        margin-left: 10%;
    }

    .FreeDayPageCard2 {
        margin-right: 10%;
    }

    .FreeDayPageCard3 {
        margin-right: 10%;
    }
}

@media screen and (min-width:1281px) {
    .FreeDayPageFooterTitle {
        font-size: 20px;
    }
    
    .FreeDayPageHeader {
        height: 200px;
    }

    .FreeDayPageBody {
        height: 400px;
        padding: 0 48px;
    }

    .FreeDayPageHeaderTitle {
        font-size: 100px;
    }

    .FreeDayPageBodyTitle {
        font-size: 80px;
    }

    .FreeDayPageCard {
        font-size: 20px;
    }

    .FreeDayPageCardSmall {
        width: 25%;
    }
    
    .FreeDayPageCardBig {
        width: 40%;
    }

    .FreeDayPageParallaxLayerCard1 {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    
    .FreeDayPageParallaxLayerCard2 {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    
    .FreeDayPageParallaxLayerCard3 {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .FreeDayPageCard1 {
        margin-left: 10%;
    }

    .FreeDayPageCard2 {
        margin-right: 10%;
    }

    .FreeDayPageCard3 {
        margin-right: 10%;
    }
}