.nyp {
    background-color: #f5f0ea;
    color: #434343;
}

.nyp-header {
    /* background: url('../../assets/background-header.png') no-repeat center center/cover; */
    background: url('../../assets/background-header5.png') no-repeat center center/cover;
    /* background: url('../../assets/new-year-2025-p3.png') no-repeat center center/cover; */
    background-size: cover;
    background-position: center;
    padding: 40px;
    text-align: center;
    color: #fff;
    position: relative;
}

.nyp-header::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 30%);
    z-index: 1;
}

.nyp-header-title {
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    color: white;
}

.nyp-header-desc {
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    font-style: italic;
    color: white;
}

.nyp-header-content {
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 1280px;
    z-index: 2;
}

.nyp-title-h1 {
    text-align: center;
    font-size: 40px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.nyp-title-h2 {
    text-align: center;
    font-size: 30px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.nyp-title-h3 {
    text-align: center;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 400;
}

.nyp-paragraph {
    text-align: center;
    font-size: 18px;
    letter-spacing: 1px;
    font-weight: 400;
    margin-top: 40px;
}

.nyp-footer-paragraph {
    color: white;
}

.nyp-header p {
    font-size: 1.2em;
    margin-top: 24px;
}

.nyp-section {
    margin: 0 auto;
    max-width: 1280px;
    padding: 40px;
    text-align: center;
}

.nyp-section-title {
    display: inline;
    position: relative;
    margin-bottom: 40px;
}

.nyp-section-title::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    border-bottom: 3px dashed #434343;
    bottom: -12px;
    left: 0;
}

.nyp-section > *:not(:last-child) {
    margin-bottom: 24px;
}


.nyp-button {
    display: inline-block;
    background-color: #d12929;
    color: white;
    padding: 15px 30px;
    text-decoration: none;
    font-size: 1.2em;
    border-radius: 5px;
    transition: background-color 0.3s;
    cursor: pointer;
    border: none;
}

.nyp-button:hover {
    background-color: rgb(134, 41, 31);
}

.nyp-button_booking {
    margin-top: 20px;
    background-color: #d12929;
}

.nyp-service-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-top: 40px;
}

.nyp-service-item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    padding: 40px;
    background-size: cover;
    background-position: center;
    color: white;
}

.nyp-service-item::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 30%);
    z-index: 1;
}

.item-content {
    position: relative;
    z-index: 2;
    color: white;
    text-shadow: 2px 2px 5px rgba(0, 0, 0);
}

.item-img1 {
    background: url('../../assets/event-item1.jpg') no-repeat center center/cover;  
}

.item-img2 {
    background: url('../../assets/event-item2.jpg') no-repeat center center/cover;
}

.item-img3 {
    background: url('../../assets/event-item3.jpg') no-repeat center center/cover;
}

.item-img4 {
    background: url('../../assets/event-item4.jpg') no-repeat center center/cover;
}

.item-img5 {
    background: url('../../assets/event-item5.jpg') no-repeat center center/cover;
}

.nyp-service-list .nyp-service-item, .nyp-gallery .nyp-gallery-img {
    width: 30%;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.nyp-gallery .nyp-gallery-img {
    max-width: 100%;
    height: auto;
}

.nyp-gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 40px;
}

.nyp-form {
    padding: 15px;
}

.nyp-form * {
    margin: 10px 0;
}

.contact-form button {
    background-color: #2ecc71;
    color: white;
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    font-size: 1.2em;
    cursor: pointer;
    transition: background-color 0.3s;
}

.contact-form button:hover {
    background-color: #27ae60;
}

.testimonials {
    text-align: center;
    background: #ffc56e;
    padding: 40px 20px;
    border-radius: 10px;
}

.testimonial {
    font-size: 1.1em;
    margin: 20px 0;
}

.nyp-footer {
    background-color: var(--brand-color-1);
    color: white;
    padding: 40px;
    text-align: center;
}

.nyp-section-about {
    background-size: cover;
    background-position: center;
}

.nyp-link {
    color: var(--brand-white);
    text-decoration: underline;
}

.nyp-link:hover {
    color: rgb(224, 224, 224);
}

.nyp-link-blue {
    color: var(--brand-color-1);
}

.nyp-error {
    color: var(--brand-red);
}

.swiper {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 80%;
    object-fit: cover;
  }
  

@media (min-width: 1280px) {
    .mySwiper-desctop {
        display: block!important;
    }

    .mySwiper-mobile {
        display: none!important;
    }
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .mySwiper-desctop {
        display: block!important;
    }

    .mySwiper-mobile {
        display: none!important;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .mySwiper-desctop {
        display: block!important;
    }

    .mySwiper-mobile {
        display: none!important;
    }
}

@media (max-width: 768px) {
    .mySwiper-desctop {
        display: none!important;
    }

    .mySwiper-mobile {
        display: block!important;
    }

    .nyp-title-h1 {
        font-size: 36px;
    }

    .nyp-title-h2 {
        font-size: 32px;
    }

    .nyp-title-h3 {
        font-size: 16;
    }

    .nyp-button {
        font-size: 1.0em;
    }

    .nyp-paragraph {
        font-size: 16px;
    }

    .nyp-section {
        padding: 20px;
    }

    .nyp-service-list .nyp-service-item, .nyp-gallery .nyp-gallery-img {
        width: 100%;
    }

    .testimonials {
        font-size: 20px;
    }
}

@media (max-width: 600px) {
    .mySwiper-desctop {
        display: none!important;
    }

    .mySwiper-mobile {
        display: block!important;
    }

    .nyp-title-h1 {
        font-size: 36px;
    }

    .nyp-title-h2 {
        font-size: 32px;
    }

    .nyp-title-h3 {
        font-size: 16;
    }

    .nyp-button {
        font-size: 1.0em;
    }

    .nyp-paragraph {
        font-size: 16px;
    }

    .nyp-section {
        padding: 20px;
    }

    .nyp-service-list .nyp-service-item, .nyp-gallery .nyp-gallery-img {
        width: 100%;
    }

    .testimonials {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .mySwiper-desctop {
        display: none!important;
    }

    .mySwiper-mobile {
        display: block!important;
    }

    .nyp-title-h1 {
        font-size: 28px;
    }

    .nyp-title-h2 {
        font-size: 20px;
    }

    .nyp-title-h3 {
        font-size: 12;
    }

    .nyp-button {
        font-size: 0.8em;
    }

    .nyp-paragraph {
        font-size: 14px;
    }

    .nyp-section {
        padding: 20px;
    }

    .nyp-service-list .nyp-service-item, .nyp-gallery .nyp-gallery-img {
        width: 100%;
    }

    .testimonials {
        font-size: 14px;
    }
}

.nyp-form-success {
    /* background: url('../../assets/background-header.png') no-repeat center center/cover; */
    background: url('../../assets/background-header5.png') no-repeat center center/cover;
    /* background: url('../../assets/new-year-2025-p3.png') no-repeat center center/cover; */
    background-size: cover;
    background-position: center;
    padding: 40px;
    text-align: center;
    color: #fff;
    position: relative;
}

.nyp-form-success-content {
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 1280px;
    z-index: 2;
}

.nyp-form-success::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 30%);
    z-index: 1;
}

.nyp-reviews {
    margin-top: 40px;
}

.swiper-pagination {
    margin-bottom: 5%;
}

.mySwiper-desctop {
    display: block!important;
}

.mySwiper-mobile {
    display: block!important;
}