.Events {
    position: relative;
    display: flex;
    background-color: var(--brand-color-2);
}

.Events-media {
    width: 100%;
    background-image: url(../../assets/djscratchcat.gif);
    background-size: contain;
    background-position: center;
}

.EventsSectionInfo {
    position: absolute;
    height: 100%;
    z-index: 1;
}

.EventsEventImg img {
    max-width: 400px;
    min-width: 400px;
}

.EventsList {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 8px;
    padding: 35px;
}