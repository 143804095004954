.NotFoundPage {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
}

.NotFoundPage-content {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    text-align: center;
}

.NotFoundPage-text {
    color: var(--brand-color-3);
    text-shadow: 5px 5px #558abb;
    text-transform: uppercase;
}

.NotFoundPage_bg {
    width: 100%;
    background-image: url(../../assets/pattern1.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.NotFoundPage-title {
    font-size: 150px;
}

.NotFoundPage-desc {
    font-size: 80px;
}

.NotFoundPage-link {
    color: var(--brand-color-3);
}