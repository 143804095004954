html, body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  line-height: 1.2;
  letter-spacing: 0.5px;
}

a, u {
  text-decoration: none;
}

li {
  list-style-type: none; 
}

ul {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
}

p {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

h1, h2, h3, h4 {
  margin-block-start: -7px;
  margin-block-end: -10px;
  margin-inline-start: 0;
  margin-inline-end: 0px;
  font-size: 0;
}

:root {
  --brand-color-1: #3FAEFF;
  --brand-color-2: #FFA05F;
  --brand-color-2-sub: #FFC7AB;
  --brand-color-3: #5fff9f;
  --brand-color-4: #bfff5f;
  --brand-color-5: #ffef5f;
  --brand-color-6: #ff5f6f;
  --first-color: #FD5B2F;
  --second-color: #686C75;
  --text-color: #000000;
  --accent-color: #3B90FB;
  --brand-white: #F9F9F9;
  --brand-gray: #B2B2B2;
  --brand-red: rgb(248, 68, 68);
}

img {
  padding:0px;
  border:0px;
  margin:-2px;
}

#root {
  height: 100%;
}
