.root {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 100;
}

.root-bg {
    background: rgba(0,0,0,.5);
}

.circle {
    animation: rotate 2s infinite linear;
    transform-origin: center center 0;
}

.fixed {
    top: 50%;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
}

@keyframes rotate {
    0% {
      transform: rotate(0deg);
      strokeDashoffset: 165;
    }
    100% {
      transform: rotate(360deg);
    }
    50% {
      strokeDashoffset: 0;
    }
    50.1% {
      strokeDashoffset: 340;
    };
}