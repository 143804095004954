.EventPage {
    position: relative;
}

.EventPageHeader {
    background-image: url(../../assets/photo_2024-01-05_18-51-50.jpg);
    width: 100%;
    height: 300px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    filter: blur(4px);
    -webkit-filter: blur(4px);
}

.EventPageTitle {
    top: 100px;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    text-align: center;
    background-color: var(--brand-white);
    padding: 24px 0;
    width: 60%;
    box-shadow: 3px 6px 12px -5px var(--brand-gray);
}

.EventPageImg img {
    max-width: 500px;
    min-width: 500px;
}

.EventPageLabel {
    font-size: 20px;
    padding: 8px;
}

.EventPageText {
    font-size: 16px;
}

.EventPageFormBlock {
    display: flex;
    justify-content: center;
    margin-top: 24px;
}

.EventPageFormBlockWrapper {
    display: flex;
    flex-direction: row;
    gap: 24px;
    width: 60%;
}

.EventPageForm {

}

.EventPageFormBlock > * {
    padding-bottom: 12px;
}

.EventPageForm > * {
    margin-bottom: 12px!important;
}

.EventPageFormButton {
    margin-top: 24px!important;
    background-color: var(--brand-color-1)!important;
}

.DialogClose {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    position: absolute;
    right: 15px;
    top: 3px;
    cursor: pointer;
    border: none;   
    background-color: transparent;
    padding: 0;
}

.EventPageFieldGroup {
    display: flex;
    gap: 12px;
}

.NumberOfPersonsHint {
    color: var(--first-color);
}

.EventPageTextMin {
    font-size: 12px;
    color: var(--brand-gray);
}

@media (min-width:320px) {
    .EventPageTitle {
        width: 80%;
    }

    .EventPageLabel {
        font-size: 16px;
    }

    .EventPageFormBlockWrapper {
        flex-direction: column;
        width: 100%;
        padding: 8px;
    }

    .EventPageImg img {
        max-width: 500px;
        min-width: 100%;
        width: 100%;
    }
}

@media (min-width:480px)  { 
    .EventPageTitle {
        width: 80%;
    }

    .EventPageLabel {
        font-size: 16px;
    }

    .EventPageFormBlockWrapper {
        flex-direction: column;
        width: 100%;
        padding: 8px;
    }

    .EventPageImg img {
        max-width: 500px;
        min-width: 100%;
        width: 100%;
    }
}

.EventPageFormWrapper > *:not(:last-child) {
    margin-bottom: 8px;
}

.EventPageExternalLink {
    font-size: 20px;
}

@media (min-width:600px)  {
    .EventPageTitle {
        width: 80%;
    }

    .EventPageLabel {
        font-size: 20px;
    }

    .EventPageFormBlockWrapper {
        flex-direction: column;
        width: 100%;
        padding: 8px;
    }

    .EventPageImg img {
        max-width: 500px;
        min-width: 100%;
        width: 100%;
    }
}

@media (min-width:801px)  { 
    .EventPageTitle {
        width: 80%;
    }

    .EventPageLabel {
        font-size: 24px;
    }

    .EventPageFormBlockWrapper {
        flex-direction: column;
        width: 60%;
    }

    .EventPageImg img {
        max-width: 500px;
        min-width: 100%;
        width: 100%;
    }
}

@media (min-width:1025px) { 
    .EventPageTitle {
        width: 60%;
    }

    .EventPageLabel {
        font-size: 24px;
    }

    .EventPageFormBlockWrapper {
        flex-direction: column;
        width: 60%;
        padding: 0;
    }

    .EventPageImg img {
        max-width: 500px;
        min-width: 100%;
        width: 100%;
    }
}

@media (min-width:1281px) { 
    .EventPageTitle {
        width: 60%;
    }

    .EventPageLabel {
        font-size: 24px;
    }

    .EventPageFormBlockWrapper {
        flex-direction: row;
        width: 60%;
        padding: 0;
    }

    .EventPageImg img {
        max-width: 500px;
        min-width: 500px;
    }
}
