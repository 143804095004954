.Header {
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: var(--brand-white);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    padding: 0 35px;
    box-shadow: -2px 6px 9px -7px var(--brand-gray);
    transition: .3s;
}

.Header_scroll {
    opacity: 0.8;
    transition: .3s;
}

.Header__logo_scroll {
    transition: .3s;
}

.Header__sidebar {
    display: none;       
}

.Header__burger {
    display: none;
}

.Header__controls {
    display: flex;
    align-items: center;
}

.Header__navbar {
    padding: 0 20px;
}

.Header__logo {
    transition: .5s;
}

@media (max-width: 860px) {
    .Header__burger {
        display: block;
    }

    .Header__navbar {
        display: none;
    }

    .Header__sidebar {
        display: block;       
    }
}